<template>
  <loader v-if="this.loaderShow != true" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2"
    bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
  <div>

    <!-- ========== Login & Registation Section ========== -->

    <section class="log-reg">
      <div class="top-menu-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <router-link to="/" class="backto-home" style="background: linear-gradient(166deg, #f22876 0%, #942dd9 100%);
              border-radius: 52px; padding: 16px; margin-top: 20px; color: white;"><i class="fas fa-chevron-left"></i>
                Back to Isna
                Matrimonials</router-link>
            </div>
            <div class="col-lg-7">
              <!-- <div class="logo">
                <img src="assets/images/logo2.png" alt="logo" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-end">
          <div class="image"></div>
          <div class="col-lg-7">
            <h3>Welcome Back!</h3>
            <div class="log-reg-inner">
              <div class="main-content">
                <!-- .prevent shortcut will stop prevent by default -->
                <form @submit.prevent="login" method="post">
                  <div class="form-group">
                    <span class="alert-danger" role="alert" v-if="result">{{
                        result
                    }}</span>
                  </div>
                  <div class="form-group">
                    <input type="email" v-model="email" class="my-form-control" placeholder="Enter Your Email" />
                  </div>
                  <div class="form-group">
                    <input type="password" v-model="password" class="my-form-control"
                      placeholder="Enter Your Password" />
                  </div>
                  <p class="f-pass">
                    Forgot your password? <router-link to="password-reset">recover password</router-link>
                  </p>
                  <div class="button-wrapper">
                    <a href="#">
                      <button type="submit" class="custom-button">Sign In</button>
                    </a>
                  </div>
                  <div class="or">
                    <p>OR</p>
                  </div>
                  <div class="or-content">
                    <!-- <p>Sign up with your email</p>
                    <a href="#" class="or-btn"
                      ><img src="assets/images/google.png" alt="" /> Sign Up with
                      Google</a
                    > -->
                    <p class="or-signup">
                      Don't have an account?
                      <router-link to="/register">Sign up here</router-link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      result: null,
      subscription: "",
      wait: "",
      loaderShow: true,
    };
  },

  methods: {
    login() {
      this.loaderShow = false;
      this.axios
        .post("login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          console.log(res, "res")
          if (res.data.data.user[0].stripe_id === null) {
            console.log('stripe id not available');
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
            localStorage.setItem('subscription', res.data.data.user[0].stripe_id);
            // localStorage.setItem('check_subscription', true);
            if(localStorage.getItem('redirect_url') == undefined){
                    localStorage.setItem('redirect_url', '');
                  }
            localStorage.setItem('billing', true);
            this.$router.push("billing");
          } else {
            this.axios.get("get_subscription_details/" + res.data.data.user[0].stripe_id, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
              .then((result) => {
                console.log(result,'result');
                this.subscription = result.data.data.status.data.length > 0 && result.data.data.status.data[0].cancel_at_period_end == false ? result.data.data.status.data[0].cancel_at_period_end : true;
                console.log(this.subscription,'this.subscription');
                if (this.subscription === true) {
                  console.log('go to billing');
                  localStorage.setItem("token", res.data.data.token);
                  localStorage.setItem("user", JSON.stringify(res.data.data.user));
                  localStorage.setItem('subscription', res.data.data.user[0].stripe_id);
                  localStorage.setItem('check_subscription', true);
                  if(localStorage.getItem('redirect_url') == undefined){
                    localStorage.setItem('redirect_url', '');
                  }
                  localStorage.setItem('billing', true);
                  this.$router.push("billing");
                } else if (this.subscription === false) {
                  console.log('go to updateProfile');
                  localStorage.setItem("token", res.data.data.token);
                  localStorage.setItem("user", JSON.stringify(res.data.data.user));
                  localStorage.setItem('subscription', res.data.data.user[0].stripe_id);
                  localStorage.setItem('check_subscription', this.subscription);
                  if(localStorage.getItem('redirect_url') == undefined){
                    localStorage.setItem('redirect_url', '');
                  }
                  localStorage.setItem('billing', false);
                  this.$router.go("updateprofile");
                }
                this.loaderShow = true;
              }).catch((err) => { this.loaderShow = true; })
          }
          // console.log('go to updateProfile');
          //         localStorage.setItem("token", res.data.data.token);
          //         localStorage.setItem("user", JSON.stringify(res.data.data.user));
          //         localStorage.setItem('subscription', res.data.data.user[0].stripe_id);
          //         localStorage.setItem('check_subscription', false);
          //         if(localStorage.getItem('redirect_url') == undefined){
          //           localStorage.setItem('redirect_url', '');
          //         }
          //         localStorage.setItem('billing', false);
          //         this.$router.go("updateprofile");

        })
        .catch((error) => {
          this.loaderShow = true;
          this.result = error.response.data.message || error.message;
        })
    },
  },
};

</script>

<style scoped>
input {
  width: 100%;
}
</style>
