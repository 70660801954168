<template>
  <loader v-if="this.LoadingTimingFlag == false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2"
    bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>

  <DashboardHeader></DashboardHeader>
  <section class="user-setting-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <Form enctype="multipart/form-data" @submit="editProfile" :validation-schema="schema">
            <div class="input-info-box mt-30">
              <div class="header">Profile setting</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="nick_name">Nick Name<span style="color:red">*</span></label>
                      <Field type="text" name="nick_name" id="nick_name" placeholder="Nick Name" v-model="nick_name" />
                      <ErrorMessage class="error-msg" name="nick_name" />
                      <!-- <span class="error-msg" v-if="errors.nick_name">{{
                        errors.nick_name
                      }}</span> -->
                    </div>
                  </div>

                  <div class="col-md-6" style="display: flex">
                    <div class="my-input-box">
                      <label for="">Profile Image</label>
                      <br />
                      <input type="file" ref="file" @change="previewImage" style="display: none">
                      <label @click="$refs.file.click()">
                        <i class="custom-button fa fa-upload mt-3"> Upload Picture</i>
                      </label>
                    </div>
                    <div v-if="preview != null">
                      <template v-if="preview">
                        <img :src="preview" class="img-fluid" />
                        <ul class="more-links">
                          <li style="float: right;">
                            <a href="javascript:void(0)" @click="delete_profile_Image">
                              <i class="fas fa-trash"></i>
                            </a>
                          </li>
                        </ul>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">Account Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">First Name<span style="color:red">*</span></label>
                      <Field type="text" placeholder="First Name" v-model="first_name" id="first_name"
                        name="first_name" />
                      <ErrorMessage class="error-msg" name="first_name" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Last Name<span style="color:red">*</span></label>
                      <Field type="text" id="last_name" name="last_name" placeholder="Last Name" v-model="last_name" />
                      <ErrorMessage class="error-msg" name="last_name" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Gender<span style="color:red">*</span></label>
                      <Field name="gender" v-model="gender" as="select">
                        <option value="" disabled selected>Select One</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Field>
                      <ErrorMessage class="error-msg" name="gender" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Date of Birth<span style="color:red">*</span></label>
                      <Field type="date" id="datepicker" name="d_o_b"
                        :max="maxDate"
                        placeholder="Date of Birth"
                        v-model="d_o_b" />
                      <ErrorMessage class="error-msg" name="d_o_b" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Country<span style="color:red">*</span></label>
                      <Field name="country" v-model="country" as="select" @change="getstates1($event)">
                        <option value="" disabled selected>Select One</option>
                        <option value="223">USA</option>
                        <option value="36">Canada</option>
                      </Field>
                      <ErrorMessage class="error-msg" name="country" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">State<span style="color:red">*</span></label>
                      <Field name="state" v-model="state" as="select">
                        <option value="" selected>Select One</option>
                        <option v-for="state in states" :key="state" :value="state.id">
                          {{ state.name }}
                        </option>
                      </Field>
                      <ErrorMessage class="error-msg" name="state" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">City<span style="color:red">*</span></label>
                      <Field name="city" v-model="city" as="select">
                         <option value="" selected>Select One</option>
                          <option v-for="city in citys" :key="city" :value="city.id">
                          {{ city.name }}
                        </option>
                      </Field>
                      <ErrorMessage class="error-msg" name="country" />
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">City<span style="color:red">*</span></label>
                      <Field type="text" placeholder="City" name="city" v-model="city" />
                      <ErrorMessage class="error-msg" name="city" />
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Zip</label>
                      <Field v-if = "zip_code != Null" type="text" placeholder="Zip" name="zip_code" v-model="zip_code" />
                      <ErrorMessage class="error-msg" name="zip_code" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Phone<span style="color:red">*</span></label>
                      <Field type="tel" placeholder="Phone" name="phone" v-model="mobile_phone" />
                      <ErrorMessage class="error-msg" name="phone" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="input-info-box mt-30">
              <div class="header">Personal Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Height</label>
                      <Field as="select" v-model="height" name="height" id="height">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="ht in heights" :key="ht" :value="ht.numeric_value">
                          {{ ht.text_value }}
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Eye Color</label>
                      <Field as="select" v-model="eyeColor" name="eyecolor" id="eyecolor">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="color in eye_colors" :key="color" :value="color.numeric_value">
                          {{ color.text_value }}
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Hair Color</label>
                      <Field as="select" v-model="hairColor" name="haircolor" id="haircolor">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="color in hair_colors" :key="color" :value="color.numeric_value">
                          {{ color.text_value }}
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Body Type</label>
                      <Field as="select" v-model="bodyType" name="bodytype" id="bodytype">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="btype in body_types" :key="btype" :value="btype.numeric_value">
                          {{ btype.text_value }}
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">Professional Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Profession</label>
                      <Field as="select" v-model="profession" name="profession" id="profession" @change="showotherprofessionfield(profession)">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="pro in professions" :key="pro" :value="pro.profession_id">
                          {{ pro.profession_name }}
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div v-if="this.check_for_other_profession == true" class="col-md-6" >
                    <div class="my-input-box">
                      <label for="">Other Profession</label>
                      <input type="text" id="other_profession" name="other_profession" placeholder="Other Profession" v-model="other_profession" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Annual Income</label>
                      <select v-model="annualIncome" name="annualincome" id="annualincome">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="income in annualIncomes" :key="income" :value="income.numeric_value">
                          {{ income.text_value }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Education</label>
                      <select v-model="education" name="education" id="education" @change="showothereducationfield(education)">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">High School</option>
                        <option value="2">Bachelors</option>
                        <option value="3">Masters</option>
                        <option value="4">Ph.D.</option>
                        <option value="5">M.D.</option>
                        <option value="6">D.D.S.</option>
                        <option value="7">J.D.</option>
                        <option value="9">D.O</option>
                        <option value="8">Other</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="this.check_for_other_education == true" class="col-md-6" >
                    <div class="my-input-box">
                      <label for="">Other Education</label>
                      <input type="text" id="other_education" name="other_education" placeholder="Other Education" v-model="other_education" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">Background Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Country of Origin</label>
                      <Field as="select" name="countryoforigin" v-model="origin" id="countryoforigin">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="country in countries" :key="country" :value="country.name">
                          {{ country.name }}
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Religion</label>
                      <select name="religion" v-model="religion" id="religion">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="religion in religions" :key="religion" :value="religion.numeric_value">
                          {{ religion.text_value }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label v-if="this.country == 223">Status in USA</label>
                      <label v-else>Status in Canada</label>
                      <select name="status" v-model="status" id="status" @change="showotherstatusfield(status)">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">Citizen</option>
                        <option value="2">Resident Alien</option>
                        <option value="3">Student Visa</option>
                        <option value="4">Other</option>
                      </select>
                    </div>
                  </div>
                  <div v-if = "this.check_for_other_status == true" class="col-md-6">
                    <div class="my-input-box">
                      <label v-if="this.country == 223">Other Status in USA</label>
                      <label v-else>Other Status in Canada</label>
                      <input v-if="this.country == 223" type="text" placeholder="Other Status in USA" id="other_status" name="other_status" v-model="other_status" />
                      <input v-else type="text" placeholder="Other Status in Canada" id="other_status" name="other_status" v-model="other_status"/>
                    </div>
                  </div>
                </div>
                <div class="header">My Language</div>
                <div class="community-section inner-page">
                  <div class="container">
                    <div class="row">
                      <div class="top-filter noBorder">
                        <div class="left">
                          <label class="mr-3 mb-4" v-for="lang in languages" :key="lang">
                            <Field class="custom-button" type="checkbox" id="language" :value="lang.numeric_value"
                              name="language" v-model="language" />
                            {{ lang.text_value }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">Other Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Marital Status</label>
                      <Field as="select" v-model="maritalStatus" name="Marital" id="Marital">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="st in marital_status" :key="st" :value="st.numeric_value">
                          {{ st.text_value }}
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Want Children</label>
                      <Field v-model="wantChild" name="wantchild" as="select" id="wantchild">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                        <option value="3">May be</option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Have Child</label>
                      <Field as="select" v-model="haveChild" name="havechild" id="havechild">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Smoke</label>
                      <Field as="select" v-model="smoke" name="smoke" id="smoke">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">About Yourself</label>
                      <textarea id="message" name="message" placeholder="Comments" v-model="message"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Desired Partner's Quality</label>
                      <textarea id="Desired" name="Desired" placeholder="Comments"
                        v-model="desiredQualities"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Disablity</label>
                      <Field as="select" v-model="disability" name="disability" id="disability">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">Refereral Information</div>
              <div class="community-section inner-page mb-n5">
                <div class="container">
                  <div class="row">
                    <div class="top-filter noBorder">
                      <div class="left">
                        <label class="mr-3 mb-4">
                          <input class="custom-button" type="checkbox" value="1" v-model="walli" />
                          Please check this if this account is for someone else and i am
                          the parent/guardian (walli)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Name</label>
                      <input type="text" v-model="guardianName" placeholder="Guardian Name" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Relation With The Person</label>
                      <input type="text" v-model="guardianRelation" placeholder="Relation" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">References Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">First Reference</label>
                      <input type="text" v-model="r1Name" placeholder="Name" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Contact Number</label>
                      <input type="text" v-model="r1Phone" placeholder="Contact Number" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Second Reference</label>
                      <input type="text" v-model="r2Name" placeholder="Name" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Contact Number</label>
                      <input type="text" v-model="r2Phone" placeholder="Contact Number" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="community-section inner-page mb-n5">
                <div class="container">
                  <div class="row">
                    <div class="top-filter noBorder">
                      <div class="left">
                        <label class="mr-3 mb-4">
                            <input class="custom-button" type="checkbox" :true-value="1" :false-value="0" v-model="profileVisbility" />
                          If you uncheck this your profile will not appear in search
                          results.</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons mt-30">
              <button type="submit" class="custom-button">Submit</button>
            </div>
            <br />
            <div class="alert alert-success" v-if="updated" role="alert">
              Profile Updated Successfully!
            </div>
          </Form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import DashboardHeader from "@/components/DashboardHeader.vue";
export default {
  name: "UpdateProfile",
  components: {
    Field,
    Form,
    ErrorMessage,
    DashboardHeader
  },

  setup() {
    const phoneRegex = /^(?:\+1\s?)?(\(?\d{3}\)?[-.\s]?)\d{3}[-.\s]?\d{4}$/;
    // Non-reactive because it was not explicitly defined with `reactive` or `ref`
    const schema = yup.object().shape({
      nick_name: yup.string().required().nullable().label("Nick Name"),
      first_name: yup.string().required().nullable().label("First Name"),
      last_name: yup.string().required().nullable().label("Last Name"),
      gender: yup.string().required().nullable().label("Gender"),
      d_o_b: yup.string().required().nullable().label("Date of Birth"),
      country: yup.string().required().nullable().label("Country"),
      state: yup.string().required().nullable().label("State"),
      city: yup.string().required().nullable().label("City"),
      // phone: yup.string().required().nullable().label("Phone"),
      phone: yup.string().required('Phone number is required').matches(phoneRegex, 'Phone number must be a valid USA or Canada number').nullable().label('Phone'),
    });
    return {
      schema,
    };
  },
  data() {
    return {
      LoadingTimingFlag: false,
      preview: null,
      image: null,
      updated: false,
      countries: "",
      states: [],
      first_name: "",
      nick_name: "",
      last_name: "",
      country: "",
      // city: "",
      citys: [],
      zip_code: "",
      state: "",
      mobile_phone: "",
      d_o_b: "",
      max_date: "",
      gender: "",
      other_state: "",
      is_approve: "",
      result: "",
      heights: "",
      height: "0",
      eye_colors: "",
      eyeColor: "0",
      hair_colors: "",
      hairColor: "0",
      body_types: "",
      bodyType: "0",
      profession: "0",
      professions: "",
      other_profession: "",
      annualIncome: "0",
      annualIncomes: "",
      education: "0",
      other_education: "",
      origin: "0",
      religion: "0",
      religions: "",
      status: "0",
      other_status: "",
      language: [],
      languages: "",
      maritalStatus: "0",
      marital_status: "",
      wantChild: "0",
      haveChild: "0",
      smoke: "0",
      message: "",
      desiredQualities: "",
      disability: "",
      walli: "",
      guardianName: "",
      guardianRelation: "",
      r1Name: "",
      r1Phone: "",
      r2Name: "",
      r2Phone: "",
      profileVisbility: 0,
      meta: [],
      profileImage: "",
      meta_data: '',
      check_for_other_status: false,
      check_for_other_education: false,
      check_for_other_profession: false,
    };
  },
  computed: {
    maxDate() {
      const current = new Date();
      let c_date = current.getDate();
      let m_date = current.getMonth() + 1;
      if(c_date < 10){
        c_date = '0'+c_date;
      }
      if(m_date < 10){
        m_date = '0'+m_date;
      }
      const date = `${current.getFullYear() - 18}-${m_date}-${c_date}`;
      // console.log(date, "Max Date")
      return date;
    },
  },

  methods: {

    previewImage: function (event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = event.target.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    // Papulate the form with availabel data from database
    getprofile() {
      this.axios
        .get("profile", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result, "UpdateProfile");
          const member = result.data.data.member_profile[0].member_id;
          if(result.data.data.member_profession.profession_name){
          this.profession = result.data.data.member_profession.profession_name;
          }
          this.meta_data = result.data.data.metavalues;
          this.userName =
            result.data.data.member_profile[0].first_name +
            " " +
            result.data.data.member_profile[0].last_name;

          if (
            result.data.data.member_profile[0].nick_name != "" ||
            result.data.data.member_profile[0].nick_name != null
          ) {
            this.nick_name = result.data.data.member_profile[0].nick_name;
          }
          this.first_name = result.data.data.member_profile[0].first_name;
          this.last_name = result.data.data.member_profile[0].last_name;
          this.gender = result.data.data.member_profile[0].gender;
          this.profileVisbility = result.data.data.member_profile[0].is_appear_in_search;
          this.city = result.data.data.member_profile[0].city;
          this.country = result.data.data.member_profile[0].country;
          this.state = result.data.data.member_profile[0].state;
          if(result.data.data.member_profile[0].zip_code != null){
          this.zip_code = result.data.data.member_profile[0].zip_code;
          }
          this.mobile_phone = result.data.data.member_profile[0].mobile_phone;
          this.d_o_b = result.data.data.member_profile[0].d_o_b;

          if (result.data.data.member_profile[0].image != null) {
            this.preview = result.data.data.member_image;
          }
          else {
            this.preview = null;
          }
          this.setprofilemeta();
          this.getprofilemeta(member);
          // this.profileImage =
          //   //get the member's meta details
          //   this.getprofilemeta(member);


        })
        .catch((err) => { });
    },
     setprofilemeta() {
          console.log("test",this.meta_data);
          for(var i = 0;i<this.meta_data.length;i++){
          if(this.meta_data[i].name == "height" && this.meta_data[i].value != 'null'){
          this.height = this.meta_data[i].value;
          }
          if(this.meta_data[i].name == "language" && this.meta_data[i].value != 'null'){
          // this.language  = this.meta_data[i].value;
          let text = this.meta_data[i].value;
          text = text + ',' + text;
          const arr = text.split(',').map(element => {
              return Number(element);
            });
            this.language = arr;
          }
          if(this.meta_data[i].name == "eye_color" && this.meta_data[i].value != 'null'){
          this.eyeColor = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "hair_color" && this.meta_data[i].value != 'null'){
            this.hairColor = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "body_type" && this.meta_data[i].value != 'null'){
            this.bodyType = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "education" && this.meta_data[i].value != 'null'){
            this.education = this.meta_data[i].value;
            this.showothereducationfield(this.education);
          }
          if(this.meta_data[i].name ==  "marital_status" && this.meta_data[i].value != 'null'){
            this.maritalStatus = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "want_childern" && this.meta_data[i].value != 'null'){
            this.wantChild = this.meta_data[i].value;

          }
          if(this.meta_data[i].name ==  "have_children" && this.meta_data[i].value != 'null'){
            this.haveChild = this.meta_data[i].value;

          }
          if(this.meta_data[i].name ==  "smoke" && this.meta_data[i].value != 'null'){
            this.smoke = this.meta_data[i].value;

          }
        }
    },
    //GET Profile Meta
    async getprofilemeta(member) {
      await this.axios
        .get("get_members_meta/" + member, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result2) => {
          console.log(result2,"get_members_meta");
          this.meta.push(result2.data.data.members_meta);
          for(var i = 0;i<this.meta[0].length;i++ ){
            if(this.meta[0][i].meta_key ==  "have_children"){
          if(this.meta[0][i].meta_value == 1){
          this.haveChild = this.meta[0][i].meta_value;
          }else if(this.meta[0][i].meta_value == 2){
            this.haveChild = this.meta[0][i].meta_value;
          }
          }
            if(this.meta[0][i].meta_key == "other_profession" && this.meta[0][i].meta_value != 'null'){
              this.other_profession = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "other_status_usa" && this.meta[0][i].meta_value != 'null'){
              this.other_status = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "origin"){
              this.origin = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "annual_income"){
              this.annualIncome = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "profession"){
              this.profession = this.meta[0][i].meta_value;
              this.showotherprofessionfield(this.profession);
            }
            if(this.meta[0][i].meta_key == "other_education" && this.meta[0][i].meta_value != 'null'){
              this.other_education = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "religion"){
              this.religion = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "usa_status"){
              this.status = this.meta[0][i].meta_value;
              this.showotherstatusfield( this.status);
              // console.log("dsfsafsdfsdfsdgregttr",this.usa_status
            }
            if(this.meta[0][i].meta_key == "message" && this.meta[0][i].meta_value != 'null'){
              this.message = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "partner_qualities" && this.meta[0][i].meta_value != 'null'){
              this.desiredQualities = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "disability" && this.meta[0][i].meta_value != 'null'){
              this.disability = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "guardian"){
              this.walli = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "guardian_name" && this.meta[0][i].meta_value != 'null'){
              this.guardianName = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "relation" && this.meta[0][i].meta_value != 'null'){
              this.guardianRelation = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "first_referance_name" && this.meta[0][i].meta_value != 'null'){
              this.r1Name = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "first_contact_num" && this.meta[0][i].meta_value != 'null'){
              this.r1Phone = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "second_referance_name" && this.meta[0][i].meta_value != 'null'){
              this.r2Name = this.meta[0][i].meta_value;
            }
            if(this.meta[0][i].meta_key == "second_contact_num" && this.meta[0][i].meta_value != 'null'){
              this.r2Phone = this.meta[0][i].meta_value;
            }
            // if(this.meta[0][i].meta_key == "is_appear_in_search"){
            //   this.other_status = this.meta[0][i].meta_value;
            // }
            this.LoadingTimingFlag = true;
          }
        })
        .catch((err) => { })
        .finally(() => {
          this.LoadingTimingFlag = true;
        });
    },
    //get countries from db
    async getcountries() {
      await this.axios
        .get("get_countries", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.countries = result.data.data.countries;
          this.getstates();
        })
        .catch((err) => { });
    },

    async getstates1(event) {
      // alert(event);
      this.LoadingTimingFlag = false;
      await this.axios
        .get("get_states", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {

          if (event != undefined) {
            this.states.splice(0);
            this.citys.splice(0);
            result.data.data.cities.forEach(element => {
             if (223 == event.target.value && element.country == "US") {
              this.citys.push(element);
              }else if(36 == event.target.value && element.country == "CA"){
                this.citys.push(element);
              }
            });
            result.data.data.states.forEach(element => {
              if (element.country_id == event.target.value) {
                this.states.push(element);
              }
      
            });
          } else {
            result.data.data.cities.forEach(element => {
             if (223 == this.country && element.country == "US") {
              this.citys.push(element);
              }else if(36 == this.country && element.country == "CA"){
                this.citys.push(element);
              }
            });
            result.data.data.states.forEach(element => {
              if (element.country_id == this.country) {
                this.states.push(element);
              }
            });
          }
        })
        .catch((err) => { })
        .finally(() => {
          this.LoadingTimingFlag = true;
        });
    },

    showotherstatusfield(event){
      if (event != undefined) {
        if(event == 4){
        this.check_for_other_status = true;
        }else{
          this.check_for_other_status = false;
        }
      }
    },
    showothereducationfield(event){
      if (event != undefined) {
        if(event == 8){
        this.check_for_other_education = true;
        }else{
          this.check_for_other_education = false;
        }
      }
    },
    showotherprofessionfield(event){
      if (event != undefined) {
        if(event == 14){
        this.check_for_other_profession = true;
        }else{
          this.check_for_other_profession = false;
        }
      }
    },
    getstates() {
      this.axios
        .get("get_states", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          result.data.data.states.forEach(element => {
            if (element.country_id == this.country) {
              this.states.push(element);
            }
          });
           result.data.data.cities.forEach(element => {
            if (223 == this.country && element.country == "US") {
              this.citys.push(element);
            }else if(36 == this.country && element.country == "CA"){
              this.citys.push(element);
            }
          });
        })
        .catch((err) => { })
        .finally(() => { });
    },

    async getProfession() {
      await this.axios
        .get("get_profession", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.professions = result.data.data.profession;
        })
        .catch((err) => { });
    },

    async editProfile() {
      var bodyFormData = new FormData();
      bodyFormData.append("nick_name", this.nick_name);
      bodyFormData.append("image", this.image);
      bodyFormData.append("first_name", this.first_name);
      bodyFormData.append("last_name", this.last_name);
      bodyFormData.append("gender", this.gender);
      bodyFormData.append("d_o_b", this.d_o_b);
      bodyFormData.append("country", this.country);
      bodyFormData.append("state", this.state);
      bodyFormData.append("city", this.city);
      bodyFormData.append("zip_code", this.zip_code);
      bodyFormData.append("mobile_phone", this.mobile_phone);
      bodyFormData.append("height", this.height);
      bodyFormData.append("eye_color", this.eyeColor);
      bodyFormData.append("hair_color", this.hairColor);
      bodyFormData.append("body_type", this.bodyType);
      bodyFormData.append("profession", this.profession);
      bodyFormData.append("other_profession", this.other_profession);
      bodyFormData.append("annual_income", this.annualIncome);
      bodyFormData.append("education", this.education);
      bodyFormData.append("other_education", this.other_education);
      bodyFormData.append("origin", this.origin);
      bodyFormData.append("religion", this.religion);
      bodyFormData.append("usa_status", this.status);
      bodyFormData.append("other_status_usa", this.other_status);
      bodyFormData.append("language", this.language);
      bodyFormData.append("marital_status", this.maritalStatus);
      bodyFormData.append("want_childern", this.wantChild);
      bodyFormData.append("have_children", this.haveChild);
      bodyFormData.append("smoke", this.smoke);
      bodyFormData.append("message", this.message);
      bodyFormData.append("partner_qualities", this.desiredQualities);
      bodyFormData.append("disability", this.disability);
      bodyFormData.append("guardian", this.walli);
      bodyFormData.append("guardian_name", this.guardianName);
      bodyFormData.append("relation", this.guardianRelation);
      bodyFormData.append("first_referance_name", this.r1Name);
      bodyFormData.append("first_contact_num", this.r1Phone);
      bodyFormData.append("second_referance_name", this.r2Name);
      bodyFormData.append("second_contact_num", this.r2Phone);
      bodyFormData.append("is_appear_in_search", this.profileVisbility);

      this.axios
        .post("edit_profile", bodyFormData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result.data.data.member_profile[0].stripe_id,"Test");
          this.updated = true;
          if (result.data.data.member_profile[0].stripe_id === null) {
            localStorage.setItem('billing', true);
            this.$router.push("billing");
          } else {
            localStorage.setItem("token", localStorage.getItem('token'));
            localStorage.setItem("user", JSON.stringify(result.data.data.member_profile));
            localStorage.setItem('subscription', localStorage.getItem('subscription'));
            localStorage.setItem('check_subscription', false);
            localStorage.setItem('billing', false);
            this.$router.push("dashboard");
          }
          // console.log('go to updateProfile');
          //         localStorage.setItem("token", res.data.data.token);
          //         localStorage.setItem("user", JSON.stringify(res.data.data.user));
          //         localStorage.setItem('subscription', res.data.data.user[0].stripe_id);
          //         localStorage.setItem('check_subscription', false);
          //         localStorage.setItem('billing', false);
          //         this.$router.push("dashboard");
        })
        .catch((err) => { });
    },

    async getReferenceData() {
      await this.axios
        .get("get_reference_data", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result);
          this.heights = result.data.data[0][0];
          this.eye_colors = result.data.data[0][1];
          this.hair_colors = result.data.data[0][2];
          this.body_types = result.data.data[0][3];
          this.annualIncomes = result.data.data[0][5];
          this.religions = result.data.data[0][7];
          this.marital_status = result.data.data[0][10];
          this.languages = result.data.data[0][9];
        })

        .catch((err) => { });
    },

    async addStripeID() {
      await this.axios
        .post("update_member", this.$route.query.session_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          localStorage.setItem('subscription', result.data.data.sub_id);
        })
        .catch((err) => { });
    },
    async delete_profile_Image() {
      var bodyFormData = new FormData();
      bodyFormData.append("profile_image", this.preview),
        await this.axios
          .post("delete_profile_image", bodyFormData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }).then((result) => {
            this.$router.go("UpdateProfile");
          })
          .catch((err) => { })
    },
  },

  mounted() {
    this.getstates();
    this.getReferenceData();
    this.getcountries();
    this.getProfession();
    this.getprofile();

    if (this.$route.query.session_id) {

      this.addStripeID();
    }

    // setTimeout(() => {
    //   // this.LoadingTimingFlag=true;
    // }, 2500)
  },
};
</script>

<style scoped>
.img-fluid {
  width: 120px;
  height: 120px;
  display: block;
  cursor: pointer;
  margin: auto;
  background-size: cover;
  background-position: center center;
}
</style>
