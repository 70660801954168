<template>
  <loader v-if="this.LoadingTimingFlag==false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2" bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
    <section class="breadcrumb-area profile-bc-area profile-section">
        <div class="container">
            <div class="content row">
                <div class="col-lg-2 col-md-3 col-sm-6 centrItemAlign">
                    <!-- profile-pic-styling -->
                    <div class="profile-img profile-pic-styling">

                        <a href="#" class="PictureeditSetting" data-toggle="modal" data-target="#UserPictureEdit" @click="PictureEditbtn">
                            <i class="fas fa-pen"></i>
                        </a>
                        <div v-if="member_profile.gender === 'Female'">
                        <img v-if="ProfileImage === ''" class="pic-styling"
                            src="../../public/assets/isna-images/femaledummy.jpg" alt="">
                            <img v-else class="pic-styling" :src="imageUrl" alt="">
                        </div>
                        <div v-if="member_profile.gender === 'Male'">
                        <img v-if="ProfileImage === ''" class="pic-styling"
                        src="../../public/assets/isna-images/dummyImage.png" alt="">
                        <img v-else class="pic-styling" :src="imageUrl" alt="">
                        </div>
                        <div class="active-online"></div>

                    </div>
                </div>

                <div class="col-lg-9 col-md-9 col-sm-6 content paddingName centrItemAlign">
                    <h2 class="title">
                        {{ member_profile.first_name }} {{ member_profile.middle_name }} {{ member_profile.last_name }}
                    </h2>
                    <ul class="breadcrumb-list">
                        <li>{{ member_profession }}</li>
                    </ul>

                    <ul class="col-lg-6 dashboardList">
                        <li>
                            <div v-if = "age">
                            <img src="../../public/assets/isna-images/AgeIcon.png" style="width:44px; height: 44px;"
                                alt="">
                            <p>{{ age }}</p>
                        </div>
                        </li>
                        <li>
                            <i class="fa fa-compress" aria-hidden="true"></i>
                            <p>66%</p>
                        </li>
                        <li>
                            <i class="fa fa-eye" aria-hidden="true"></i>
                            <p>{{ Profile_view }}</p>
                        </li>

                    </ul>

                  </div>

                <div class="col-lg-5 col-sm-12 maindiv row p-2 centrItemAlign paddingName" v-if="isLoggedIn">
                    <div class="col-lg-6 col-sm-6 col-6 content">
                        <button class="custom-button">
                            <i class="far fa-heart"></i> Add to Favourites
                        </button>
                        <button class="custom-button">
                            <i class="far fa-heart"></i> Remove Favourite
                        </button>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-6 content">
                        <router-link class="custom-button">
                            <i class="far fa-comment-dots"></i> Send Message
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="modal fade" id="UserPictureEdit"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true" v-if="this.modalShow==true">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="close closeModal " data-dismiss="modal" aria-label="Close"
                    style="width: 35px; height: 35px;line-height: 30px;">
                    <span aria-hidden="true">&times;</span>
                </div>
                <form enctype="multipart/form-data" @submit="editProfile" :validation-schema="schema">
                    <div class="modal-body">
                        <div class="header-area">
                            <h5>Upload Image</h5>
                        </div>
                        <div class="notification-list">
                            <div class="single-list">
                                <div class="row">
                                    <div class="co-lg-12 col-md-12">
                                        <div class="top-filter">
                                            <div class="left">
                                                <div v-if="preview">
                                                    <img :src="preview" class="img-preview" />
                                                </div>
                                                <div class="col-md-12" style="display: flex" v-if="this.Ispictureuploaded==false">
                                                    <div class="my-input-box">
                                                        <input type="file" ref="file" @change="previewImage"
                                                            style="display: none">
                                                        <label @click="$refs.file.click()">
                                                            <i class="custom-button fa fa-upload mt-3"> Upload
                                                                Picture</i>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12" style="display: flex" v-if="this.Ispictureuploaded==true">
                                                    <div class="my-input-box">
                                                        <input type="file" ref="file" @change="previewImage"
                                                            style="display: none">
                                                        <label @click="$refs.file.click()">
                                                            <i class="custom-button fa fa-upload mt-3"> Change
                                                                Picture</i>
                                                        </label>
                                                    </div>
                                                </div>
                                                <p v-if="this.profileImageChange == true" class="alert alert-success">Picture Updated
                                                    Successfully</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="view-all-link" data-dismiss="modal"  v-if="this.Ispictureuploaded==false">cancel</button>
                        <button type="submit" class="view-all-link"  v-if="this.Ispictureuploaded==true">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <TopMenu></TopMenu>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue';
export default {
    name: "DashboardHeader",
    data() {
        return {
            preview: null,
            image: null,
            member_profile: "",
            member_profession: "",
            imageUrl: "",
            member_d_o_b: '',
            ProfileImage: "",
            profileImageChange:false,
            Ispictureuploaded:false,
            modalShow: true,
            Profile_view: 0,
            LoadingTimingFlag: false,

        };
    },
    methods: {
        async PictureEditbtn(){
            this.preview=null;
            this.Ispictureuploaded=false;
        },
        async getprofile() {
            await this.axios
                .get("profile", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((result) => {
                    console.log(result, 'Profile')
                    if(result.data.data.member_image){
                    this.imageUrl = result.data.data.member_image;
                    }else{
                        this.imageUrl = '';
                    }
                    if(result.data.data.member_profile[0].image){
                    this.ProfileImage = result.data.data.member_profile[0].image
                    }else{
                        this.ProfileImage
                    }
                    console.log(this.ProfileImage,"image");
                    // this.member_id = result.data.data.member_profile[0].member_id;
                    this.member_profile = result.data.data.member_profile[0];
                    this.member_profession = result.data.data.member_profession.profession_name;
                    this.member_d_o_b = result.data.data.member_profile[0].d_o_b;
                    this.Profile_view = result.data.data.member_profile[0].profile_vist;
                    this.modalShow = true
                    this.getAge()
                    this.LoadingTimingFlag = true;

                })
                .catch((err) => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("token");
                  localStorage.removeItem("subscription");
                  localStorage.removeItem("check_subscription");
                  localStorage.removeItem("billing");
                  localStorage.removeItem("setupTime");
                  window.location.replace("login");
                });
        },
        previewImage: function (event) {
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                    this.Ispictureuploaded=true;
                };
                this.image = event.target.files[0];
                reader.readAsDataURL(input.files[0]);
            }
        },

        getAge() {
            var dateString = this.member_d_o_b;
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            this.age = age;
            return age;
        },

        async editProfile(e) {
            e.preventDefault();
            var bodyFormData = new FormData();
            bodyFormData.append("image", this.image);
            this.axios
                .post("edit_profile", bodyFormData, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((result) => {
                    // console.log(result, "image")
                    this.updated = true;
                    this.profileImageChange=this.updated
                    setTimeout(() => {
                        this.profileImageChange=false
                    }, 2500)
                    this.modalShow = false
                    this.getprofile();
                    $("div").removeClass("modal-backdrop");
                    $(".modal-open").css({paddingRight: "0px"});
                    $('body').removeClass('modal-open');

                })
                .catch((err) => { });
        },
    },
    mounted() {
        this.getprofile();
    },
    components: { TopMenu }
}
</script>

<style scoped>


.dashboardList {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-left: 0px;
    text-align: center;
}

.fa-compress,
.fa-eye {
    margin-top: 16px;
}

.pic-styling {
    border-radius: 20px;
    width: 150px;
    height: 150px;
}

.PictureeditSetting {
    position: relative;
    font-size: 25px;
    color: white;
    float: right;
    margin-left: -49px;
    background: linear-gradient(166deg, #f22876 0%, #942dd9 100%);
    /* -webkit-box-shadow: 0.872px 9.962px 20px 0px rgb(12 78 165 / 30%); */

}

.fa-pen {
    font-weight: 600;
    font-size: 20px;
    margin: 10px;
}


.breadcrumb-area {
    padding: 120px 0px 20px !important;
}

.single-list {
    justify-content: center;
}

.img-preview {
    width: 144px;
    height: 165px;
    margin-left: 30px;
}

.closeModal {
    font-size: 27px;
    margin: 19px;
    text-align: center;
    width: 34px;
    height: 34px;
    line-height: 31px;
}

h5 {
    margin-top: 15px;
    margin-left: 18px;
}

@media (min-width: 768px) and (max-width:1900px) {
    .paddingName {
        top: 10px;
    }

    .modal-dialog {
        width: 60%;
    }

    h2 {
        font-size: 39px;
        line-height: 60px;
        margin-top: -13px;
    }

    .PictureeditSetting {
        position: relative;
        font-size: 36px;
        color: white;
        float: right;
        margin-left: -49px;
        background: linear-gradient(166deg, #f22876 0%, #942dd9 100%);
        /* -webkit-box-shadow: 0.872px 9.962px 20px 0px rgb(12 78 165 / 30%); */

    }

}

@media (max-width: 768px) {
    .modal-dialog {
        width: 96%;
    }
}
</style>
